.containers{
    display:flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    
}
.items{

    height: 500px;
    margin: 50px;
    background-color: rgb(255, 255, 255);
    border: 10px;
    flex-grow: 1px;
    flex-basis: 350px;
    margin-top: 3%;
    border-radius: 5%;
    
    
}
.group {
    display: flex;
    justify-content: stretch;
}
.Grid_image{
    border-radius: 5%;
    margin-top: 10%;
    margin-left: 10%;
    width:80%;
    height:37%;
   
   
}
.Gridtext_description{
    border-radius: 5%;
    margin-top: 10%;
    margin-left: 10%;
    width:80%;
    height:37%;
    

}
.Recomand_texts{
    margin-top: 2%;
}
.Recomand_texts h2{
    font-size: 40px;
    color: aliceblue;
    text-align: center;

}
.Recomand_texts p{
    margin-top: 2%;
    font-size: 20px;
    color: aliceblue;
    text-align: center;

}
@media (max-width: 600px) {

    .items{
        margin:auto;
    }
    .Grid_image{
        margin:auto;
    }
    .Gridtext_description{
        margin:auto;
    }

    .group {
        flex-direction: column;
        gap: 20px;
        margin: 50px;
    }
}