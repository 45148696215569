@use "../../shared/css/utils.scss" as *; // media-query

.landing {
  &_section {
    position: relative;
    flex-direction: column;
    overflow: hidden;

    @include max {
      display: flex;
    }

    @include md {
      display: none;
    }
  }
  &_flex_col {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 60px auto 60px;
    width: 600px;
  }
  &_title {
    display: flex;
    justify-content: center;
    font: 700 56px/1.2 "Urbanist", Helvetica, Arial, serif;
    color: rgb(255, 255, 255);
    text-align: center;
  }
  &_title2 {
    display: flex;
    justify-content: center;
    margin: 16px 0px;
    font: 400 18px/1.5 "Roboto", Helvetica, Arial, serif;
    color: rgb(255, 255, 255);
    text-align: center;
  }
  &_flex_row {
    display: flex;
    align-items: center;
    gap: 0px 24px;
    margin: 40px 0px 0px;
    width: 50%;
  }
  &_button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding-right: 16px;
    max-width: 100%;
    font: 400 16px/1.5 "Roboto", Helvetica, Arial, serif;
    color: rgb(255, 255, 255);
    text-align: center;
    background-color: rgb(13, 13, 13);
    border-radius: 8px 8px 8px 8px;
    outline: 1px solid rgb(255, 255, 255);
    outline-offset: -1px;
    cursor: pointer;
  }
  &_image2 {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 490px;
    height: 870px;
  }
}

// mobile
.mobile_landing {
  &_section {
    flex-direction: column;

    @include max {
      display: none;
    }

    @include md {
      display: flex;
    }
  }
  &_image {
    width: 100%;
    height: auto;
  }
  &_flex_col {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 200px auto 200px;
    width: 900px;
    max-width: 85%;
    @include md {
      margin: 50px auto 80px;
    }
    @include xs {
      margin: 80px auto;
    }
    @include tn {
      margin: 50px auto;
    }
  }
  &_title {
    display: flex;
    justify-content: center;
    font: 600 56px/1.2 "Urbanist", Helvetica, Arial, serif;
    color: rgb(255, 255, 255);
    text-align: center;
    letter-spacing: -0.1px;
    padding-bottom: 0;
    @include md {
      align-items: flex-start;
      font-size: 50px;
    }
    @include xxs {
      font-size: 36px;
    }
  }
  &_title2 {
    display: flex;
    justify-content: center;
    margin: 0 0px 0px;
    font: 300 16px/1.5 "Roboto", Helvetica, Arial, serif;
    color: rgb(255, 255, 255);
    text-align: center;
  }
  &_flex_row {
    display: flex;
    align-items: center;
    gap: 0px 24px;
    margin: 40px 0px 0px;
  }
  &_icon {
    width: 135px;
    border-radius: 10px 10px 10px 10px;
    outline: 1px solid rgb(249, 249, 249);
    outline-offset: -1px;
    cursor: pointer;
  }
  &_button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding-right: 16px;
    max-width: 100%;
    font: 400 16px/1.5 "Roboto", Helvetica, Arial, serif;
    color: rgb(255, 255, 255);
    text-align: center;
    background-color: rgb(13, 13, 13);
    border-radius: 8px 8px 8px 8px;
    outline: 1px solid rgb(255, 255, 255);
    outline-offset: -1px;
    cursor: pointer;
  }
  a {
  cursor: pointer;
  }
}
