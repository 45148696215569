:root {
  --Urbanist: "Urbanist", Helvetica, Arial, serif;
  --Roboto: "Roboto", Helvetica, Arial, serif;
}

.about-section {
  padding: 24px 7% 175px;
  background-image: url(../../assets/bg-aboutpage.png);
  background-position: 0;
  background-size: cover;
  background-repeat: no-repeat;
}

.about-topPart {
  display: flex;
  justify-content: start;
  width: 100%;
  margin-bottom: 40px;
}
.backarrow-cont {
  display: flex;
  align-items: center;
  color: #ffffff;
  font-family: var(--Urbanist);
  font-size: 20px;
  cursor: pointer;
}

.backarrow {
  font-size: 1.7rem;
}
.backarrow-cont p {
  font-weight: 200;
}

.about-content {
  display: flex;
  align-items: center;
  gap: 50px;
  color: #ffffff;
}
.leftSide {
  width: 50%;
}

.leftSide h1 {
  font: 600 56px/1.2 var(--Urbanist);
}
.leftSide p {
  font: 300 16px/1.5 var(--Urbanist);
  margin-bottom: 24px;
}

.rightSide {
  width: 45%;
}
.rightSide img {
  width: 100%;
}

@media (max-width: 1200px) {
  .about-section {
    padding: 24px 10.7% 175px;
  }
}

@media (max-width: 990px) {
  .about-section {
    background-image: url(../../assets/bg-mobile-aboutpage.png);
    background-position-x: -311px;
    background-position-y: -244px;
    padding: 24px 10.7% 150px;
  }
  .about-content {
    flex-direction: column;
  }
  .leftSide {
    width: 100%;
  }
  .rightSide {
    width: 100%;
  }
}
