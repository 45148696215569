.help-card {
  border: 1px solid #444;
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #222;
  margin: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease,
    background-color 0.3s ease;
}

.help-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6);
  background-color: #333;
}

.help-card img {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #444;
}

.help-card .card-content {
  padding: 20px;
}

.help-card .section-name {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 10px;
}

.help-card .description {
  font-size: 15px;
  color: #ddd;
}
