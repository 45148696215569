.box {
    padding: 16px;
    margin: 0vw 10vw 10vw 10vw;
    color: white;
    text-align: left;
}

h1, h2, h3, h4, h5 {
    padding: 16px;
}

p {
    padding-left: 16px;
}

li {
    margin: 16px;
}