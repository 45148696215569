@use "../../shared/css/utils.scss" as *; // media-query

.recommend_section{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  margin: 0px 50px 100px;
}
.rc_title {
  display: flex;
  justify-content: center;
  margin: 50px 0px 0px;
  font: 600 48px/1.2 "Urbanist", Helvetica, Arial, serif;
  color: rgb(255, 255, 255);
  text-align: center;

  @include md {
    font-size: 36px;
  }
}

.rc_subtitle {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 39px 0px 0px;
    font: 300 18px/1.5 "Roboto", Helvetica, Arial, serif;
    color: rgb(255, 255, 255);
    text-align: center;
  }

.rc_flex_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0px 30px;
  margin: 80px 0px 200px;
  width: 80%;

  @include md {
    flex-direction: column;
    gap: 30px 0px;
  }
}
.rc_image {
  width: 200px;

  @include md {
    width: 100px;
  }
}

