:root {
  --Urbanist: "Urbanist", Helvetica, Arial, serif;
  --Roboto: "Roboto", Helvetica, Arial, serif;
}

.pricing-sect {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 34px;
  margin-top: 20px;
}

.pricing-text-cont {
  width: 63%;
}
.pricing-text-cont h1 {
  text-align: center;
  color: white;
  font: 600 56px/1.2 var(--Urbanist);
}
.pricing-text-cont p {
  text-align: center;
  color: white;
  font: 300 16px/1.5 var(--Urbanist);
}

.priceplan-section {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 18px;
  width: 100%;
  padding: 50px 7%;
  background-image: url(../assets/bg-aboutpage.png);
  background-position: 0;
  background-size: cover;
  background-repeat: no-repeat;
}

.priceplan-container {
  flex-grow: 1;
  display: grid;
  grid-template-rows: 260px;
  width: 32%;
  border-radius: 10px;
  backdrop-filter: blur(12px);
  border: 2px solid #434343a5;
  transition: all;
  transition-duration: 300ms;
}
.priceplan-container:hover {
  border: 2px solid white;
  transform: scale(101%);
  transition: all;
  transition-duration: 300ms;
}

.planHead-cont {
  width: 100%;
  padding: 10px 20px auto;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom: 1.5px dashed rgb(138, 135, 135);
}

.planHead-text h3 {
  font: 700 32px/1.5 var(--Urbanist);
  color: white;
}
.planHead-text h4 {
  font: 700 2.7rem/1 var(--Urbanist);
  background-image: linear-gradient(180deg, rgb(153, 151, 151), white);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.planHead-text a {
  text-decoration: underline;
  cursor: pointer;
}
.planHead-text p {
  font: 300 17px/1.4 var(--Urbanist);
  color: rgb(228, 229, 237);
}
.planHead-text span {
  font-weight: 600;
}
.offer-desc {
  font: 500 20px/1 var(--Urbanist);
  margin-bottom: 10px;
  padding: 30px 20px 0px;
  color: white;
}
.offer-container {
  padding: 0px 15px 40px 25px;
  color: white;
  height: 670px;
}
.offer-container li {
  position: relative;
  line-height: 28px;
  margin-bottom: 6px;
  margin-top: 7px;
}

.sub-list li {
  list-style-type: square;
  margin-left: 20px;
  font-weight: 400;
}

.extra-info {
  font: 500 18px/1 var(--Urbanist);
  font-style: italic;
  margin-top: 30px;
  padding: 0px 20px 0px;
  color: white;
}
.price-cta {
  padding-left: 20px;
  padding-right: 20px;
}
.price-cta button {
  width: 100%;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid rgb(228, 229, 237);
  border-radius: 10px;
  padding: 20px 0;
  color: white;
  text-align: center;
  height: auto;
  margin-top: 30px;
  margin-bottom: 25px;
  font-size: 1rem;
  transition: all;
  transition-duration: 300ms;
}
.price-cta button:hover {
  color: black;
  background-color: white;
  transition: all;
  transition-duration: 300ms;
}

.mob_app-sect {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  padding: 50px 7%;
  color: white;
}
.left-side {
  width: 47%;
}
.mob_app-title {
  font: 500 2.2rem/1.25 var(--Urbanist);
  padding-left: 0;
}
.mob_app-subtext {
  font: 400 1rem/1.5 var(--Roboto);
  padding-left: 0;
}

.offer-list {
  background-color: #4a4a4a1c;
  border-radius: 40px;
  backdrop-filter: blur(4px);
  padding: 30px 40px;
  margin-top: 28px;
  font-family: var(--Roboto);
}
.offer-list h4 {
  padding: 0;
  font-weight: 500;
}
.request-demo-btn {
  display: block;
  padding: 0;
}
.request-demo-btn button {
  margin-bottom: 6px;
}
.schedule-text {
  display: block;
  text-align: center;
  color: rgb(179, 176, 176);
  font-size: 0.8rem;
}
.right-side {
  width: 47%;
}
.right-side img {
  width: 100%;
}

@media (max-width: 990px) {
  .pricing-text-cont h1 {
    font: 600 48px/1.2 var(--Urbanist);
  }
  .priceplan-section {
    flex-wrap: wrap;
    justify-content: start;
    background-image: url(../assets/bg-mobile-aboutpage.png);
    background-position: -400px;
  }
  .priceplan-container,
  .left-side {
    width: 100%;
  }

  .pricing-text-cont,
  .right-side {
    width: 75%;
  }
  .priceplan-container {
    display: flex;
    flex-direction: column;
  }
  .planHead-text {
    padding-bottom: 10px;
  }
  .mob_app-sect {
    flex-direction: column-reverse;
  }
  .offer-list {
    padding: 30px;
  }
}

@media (max-width: 723px) {
  .pricing-text-cont {
    width: 90%;
  }
  .pricing-text-cont h1 {
    font-size: 32px;
  }
  .mob_app-title {
    font: 500 1.9rem/1.25 var(--Urbanist);
  }
}

@media (max-width: 330px) {
  .offer-container {
    height: auto;
  }
  .extra-info {
    font-size: 16px;
  }
}
