.text_container{
    
    position: relative; 
    padding-bottom: 60%; 
    overflow:hidden;
    /* width:100%;
    height: 100%; */
    
}
.text_container iframe,
.text_container object,
.text_container embed{ 
    position: absolute;
    top: 0;
    left:0;
    width:100%;
    height: 100%;
    background: transparent;
}