.help-center-brand {
  background-image: url("./../../../../../assets/banner_help_center__1__720.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  justify-content: center;
  color: #fff;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}

.help-center-brand h1 {
  font-size: 48px;
  font-weight: 500;
  margin: 0;
}

.search-container {
  position: relative;
  width: 100%;
  max-width: 600px;
}

.search-container input {
  width: 100%;
  max-width: 600px;
  padding: 15px 40px 15px 20px;
  border-radius: 20px;
  border: 1px solid #444;
  background-color: #222;
  color: #fff;
  font-size: 14px;
  outline: none;
}

.search-container input::placeholder {
  color: #888;
}

.search-container i {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  color: #888;
}

.popular-actions {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  flex-direction: row;
  align-items: center;
}

.popular-actions span {
  margin-right: 10px;
}

.popular-actions__item {
  color: #fff;
  text-decoration: underline;
  margin-right: 4px;
  font-size: 14px;
  cursor: pointer;
  display: block;
}

@media (max-width: 769px) {
  .help-center-brand {
    text-align: center;
    align-items: center;
  }
  .help-center-brand h1 {
    font-size: 32px;
    font-weight: 500;
    margin: 0;
  }

  .popular-actions {
    flex-direction: column;
    align-items: start;
    justify-content: start;
  }
}
