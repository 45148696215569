@use "../../shared/css/utils.scss" as *; // media-query

.insights {
  &_section {
    display: flex;
    flex-direction: column;

    @include max {
      display: flex;
    }

    @include md {
      display: none;
    }
  }
  &_flex_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0px 10px;
    margin: 200px auto 200px;
    width: 85%;
  }
  &_flex_col {
    display: flex;
    flex-direction: column;
    gap: 85px 0px;
    width: 241px;
  }
  &_flex_col1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &_icon {
    width: 50px;
    height: 50px;
    color: white;
    font-size: 32px;
  }

  &_image {
    width: auto;
  }
  &_medium_title {
    display: flex;
    justify-content: center;
    margin: 16px 0px 0px;
    font: 700 24px/1.5 "Roboto", Helvetica, Arial, serif;
    color: rgb(255, 255, 255);
    text-align: center;
  }
  &_highlight {
    display: flex;
    justify-content: center;
    margin: 16px 0px 0px;
    font: 400 16px/1.5 "Roboto", Helvetica, Arial, serif;
    color: rgb(255, 255, 255);
    text-align: center;
  }
}


// mobile
.mobile_insights {
  &_section {
    flex-direction: column;
    justify-content: center;
    @include max {
      display: none;
    }

    @include md {
      display: flex;
    }
  }
  &_flex_row {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0px 10px;
    margin: 50px auto 0px;
  }
  &_flex_col {
    display: flex;
    flex-direction: column;
    gap: 0px 85px;
    
    justify-content: center;
  }
  &_flex_col1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 32px;
  }
  &_icon {
    width: 50px;
    height: 50px;
    color: white;
    font-size: 32px;
  }

  &_image {
    width: 100%;
    margin-bottom: 48px;
  }
  &_medium_title {
    display: flex;
    justify-content: center;
    margin: 16px 0px 0px;
    font: 700 24px/1.5 "Roboto", Helvetica, Arial, serif;
    color: rgb(255, 255, 255);
    text-align: center;
  }
  &_highlight {
    display: flex;
    justify-content: center;
    margin: 16px 0px 0px;
    font: 400 16px/1.5 "Roboto", Helvetica, Arial, serif;
    color: rgb(255, 255, 255);
    text-align: center;
  }
}
