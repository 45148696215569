.funrs-sect {
  padding: 40px 136px 80px;
}
.funrs-flex-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.funrs-img-cont {
  width: 40%;
}
.funrs-img-cont img {
  width: 100%;
}
.funrs-txt-cont {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 50%;
}
.funrs-headtxt {
  font: 700 48px/1.2 "Urbanist", Helvetica, Arial, serif;
  color: rgb(255, 255, 255);
  letter-spacing: -0.1px;
}
.funrs-ptxt {
  font: 300 16px/1.5 "Roboto", Helvetica, Arial, serif;
  color: rgb(255, 255, 255);
  margin: 10px 0 25px;
}
.funrs-btn {
  display: flex;
  align-items: center;
  margin: 16px;
  padding: 0px 20px;
  border: 1px solid white;
  border-radius: 20px;

  cursor: pointer;
  font: 200 20px/1.5 "Roboto", Helvetica, Arial, serif;
  transition: all 200ms ease-in-out;
}
.funrs-btn:hover {
  background-color: transparent;
  color: white;
}

@media (max-width: 990px) {
  .funrs-sect {
    padding: 40px 86px 80px;
  }
  .funrs-flex-box {
    flex-direction: column-reverse;
    gap: 40px;
  }
  .funrs-img-cont {
    width: 100%;
  }
  .funrs-txt-cont {
    width: 100%;
  }
  .funrs-headtxt {
    font-size: 50px;
  }
}

@media (max-width: 500px) {
  .funrs-sect {
    padding: 40px 46px;
  }
  .funrs-headtxt {
    font-size: 36px;
  }
}
